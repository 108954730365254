





import { IOrganization } from '@/types/projects'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'organization-proof-status'
})
export default class OrganizationProofStatus extends Vue {
  @Prop() organization!: IOrganization

  get label (): string {
    let labelHtml = '-'
    const expired = this.organization.is_notice_of_exemption_expired
    if (expired !== undefined) {
      if (!expired) labelHtml = `<span class="label label-success">${this.$gettext('Valid')}</span>`
      else labelHtml = `<span class="label label-danger">${this.$gettext('Expired')}</span>`
    }
    return labelHtml
  }
}
