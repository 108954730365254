
import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { IUserProfile } from '@/types/users'

@Component({
  name: 'user-mixin'
})
export default class UserMixin extends Vue {
  get userProfile (): IUserProfile | null {
    return UserModule.profile
  }

  hasPermission (permissionName: string) {
    return UserModule.hasPermission(permissionName)
  }

  async fetchUserProfile () {
    if (UserModule.profile === null) {
      await UserModule.fetchProfile()
    }
  }

  created () {
    this.fetchUserProfile()
  }
}
