
import {
  VuexModule, Module, Action, Mutation, getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { IUserProfile } from '@/types/users'
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'

export interface IUserState {
  profile: IUserProfile | null
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  profile: IUserProfile | null = null

  get hasPermission () {
    return (permissionName: string) => {
      if (this.profile && this.profile.is_superuser) return true
      return this.profile && this.profile.permissions.includes(permissionName)
    }
  }

  @Mutation
  FETCH_PROFILE (profile: IUserProfile) {
    this.profile = profile
  }

  @Action({ commit: 'FETCH_PROFILE' })
  async fetchProfile () {
    const res = await axios.get(API_URLS.USERS.PROFILE).then(res => res.data)
    return res
  }
}

export const UserModule = getModule(User)
