





import { Component, Prop, Vue } from 'vue-property-decorator'
import { IProject } from '@/types/projects'

@Component({
  name: 'project-review-status'
})
export default class ProjectReviewStatus extends Vue {
  @Prop() project!: IProject

  get label (): string {
    let labelHtml = '-'
    const status = this.project.review_status
    if (status) {
      if (status.choice === 2) labelHtml = `<span class="label label-success">${status.display}</span>` // public
      else if (status.choice === 3) labelHtml = `<span class="label label-danger">${status.display}</span>` // rejected
      else if (status.choice === 4) labelHtml = `<span class="label label-success">${status.display}</span>` // accepted
    }
    if (this.project.needs_donation_review) {
      const needsReviewLabel = `<span class="label label-warning">${this.$gettext('Needs review')}</span>`
      if (labelHtml.length > 1) labelHtml += needsReviewLabel
      else labelHtml = needsReviewLabel
    }
    return labelHtml
  }
}
