


























import { Component, Prop, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import Modal from '@/components/bootstrap3/Modal.vue'
import SfCheckboxGroup from '@/components/SfCheckboxGroup.vue'
import ToastMixin from '@/mixins/ToastMixin'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'TableSettingsModal',
  components: {
    Modal,
    SfCheckboxGroup
  }
})
export default class TableSettingsModal extends Mixins(ToastMixin) {
  @Prop({ default: [] }) fields!: { key: string, label: string }[]
  @Prop({ default: [] }) hiddenFields!: string[]
  @Prop({ default: () => '' }) tableName!: string
  @Prop({ default: false }) isOpen!: boolean
  formData: {[key: string]: boolean} = {}
  saving = false

  async saveTableSettings () {
    this.saving = true
    const tableSettingsRes = await axios.patch(
      API_URLS.TABLE_SETTINGS(this.tableName),
      { [this.tableName]: Object.keys(this.formData).map(key => !this.formData[key] ? key : false).filter(item => item).join("/") },
      { headers: { 'X-CSRFToken': this.$cookies.get('csrftoken') } }
    ).then(response => response.data
    ).catch(() => {
      this.makeToast('danger', this.$gettext('Error'), this.$gettext('Could not save new table settings'))
    })
    this.saving = false
    this.$emit('success', tableSettingsRes)
  }

  created () {
    for (const key in this.fields) {
      this.$set(this.formData, this.fields[key].key, !this.hiddenFields.includes(this.fields[key].key))
    }
  }
}
