
































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
  @Prop({ default: '' }) containerClasses!: string
  @Prop({ default: '' }) modalBodyClasses!: string
  @Prop({ default: false }) scroll!: boolean

  get computedContainerClasses () {
    const _containerScaling = 'modal-sm-100 modal-md-70 modal-lg-40 modal-xl-30'
    return `${this.containerClasses} modal-container ${!this.containerClasses.length ? _containerScaling : ''}`
  }

  get computedModalBodyClasses () {
    return `${this.modalBodyClasses} modal-body`
  }
}
