
import {
  VuexModule, Module, Action, Mutation, getModule
} from 'vuex-module-decorators'
import camelcaseKeys from 'camelcase-keys'
import store from '@/store'
import { API_URLS } from '@/utils/helpers'

export interface IPlatformFeatures {
  enableDonating: boolean
  enableVoting: boolean
  enableDonations: boolean
  enableSponsoring: boolean
  enableFoundation: boolean
  enableGewinnsparen: boolean
  enablePrivateDonations: boolean
  enablePromoterDonations: boolean
  enableCoinDonations: boolean
  enableInternalFunding: boolean
  enableReminderFeature: boolean
  enableExtendedReviewMode: boolean
  enableCustomIds: boolean
}

interface IPlatformFeaturesState {
  platformFeatures: IPlatformFeatures | null
}

@Module({ dynamic: true, store, name: 'platformFeatures' })
class PlatformFeatures extends VuexModule implements IPlatformFeaturesState {
  platformFeatures: IPlatformFeatures | null = null

  @Mutation
  SET_PLATFORM_FEATURES (platformFeatures: IPlatformFeatures) {
    this.platformFeatures = platformFeatures
  }

  @Action({ commit: 'SET_PLATFORM_FEATURES' })
  async fetchPlatformFeatures () {
    const platformFeaturesRes = await fetch(API_URLS.PLATFORMFEATURES.DEFAULT)
      .then(res => {
        if (!res.ok) { throw new Error(res.statusText) }
        return res.json()
      })
    return camelcaseKeys(platformFeaturesRes, { deep: true })
  }
}

export const PlatformFeaturesModule = getModule(PlatformFeatures)
