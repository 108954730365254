
import {
  VuexModule, Module, Action, Mutation, getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { IInternalStatus, IAPIFilterCtx } from '@/types/base'
import { addContextToUrl, API_URLS } from '@/utils/helpers'
import { IProject } from '@/types/projects'

const _fetch = (url: string) => {
  return fetch(url).then(res => {
    if (!res.ok) { throw new Error(res.statusText) }
    return res.json()
  })
}

export interface IProjectsState {
  projects: IProject[]
  projectCount: number
  internalStatuses: IInternalStatus[]
}

@Module({ dynamic: true, store, name: 'projects' })
class Projects extends VuexModule implements IProjectsState {
  projects: IProject[] = []
  projectCount = 0
  internalStatuses: IInternalStatus[] = []

  @Mutation
  FETCH_PROJECTS (projectRes: { projects: IProject[], count: number }) {
    this.projects = projectRes.projects
    this.projectCount = projectRes.count
  }

  @Action({ commit: 'FETCH_PROJECTS' })
  async fetchProjects (ctx: IAPIFilterCtx) {
    const res = await _fetch(addContextToUrl(API_URLS.PROJECTS.LIST, ctx))
    return { projects: res.results || [], count: res.count }
  }

  @Mutation
  FETCH_INTERNAL_STATUSES (statuses: IInternalStatus[]) {
    this.internalStatuses = statuses
  }

  @Action({ commit: 'FETCH_INTERNAL_STATUSES' })
  async fetchInternalStatuses () {
    const url = `${API_URLS.PROJECTS.INTERNAL_STATUS}?page_size=100`
    const res = await _fetch(url)
    return res.results || []
  }
}

export const ProjectsModule = getModule(Projects)
