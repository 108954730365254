
























































































import { Component, Prop, Watch, Ref, Vue } from 'vue-property-decorator'
import { BvTableCtxObject, BvComponent } from 'bootstrap-vue'
import InputGroup from '@/components/bootstrap3/InputGroup.vue'
import SfCheckbox from '@/components/SfCheckbox.vue'
import { IFilter, IFilters } from '@/types/filters'
import PageSizeSelect from '@/components/PageSizeSelect.vue'
import TableFilterDropdown from '@/components/TableFilterDropdown.vue'
import axios from 'axios'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { addContextToUrl } from '@/utils/helpers'
import { BvTableFieldArrayWithStickColumn } from '@/types/base'

@Component({
  components: {
    InputGroup,
    SfCheckbox,
    TableFilterDropdown,
    PageSizeSelect
  }
})
export default class GenericTable extends Vue {
  // Table field definitions. Check https://bootstrap-vue.js.org/docs/components/table/ for more
  @Ref() readonly selectableTable!: BvComponent
  @Prop({ default: false }) archived!: boolean
  @Prop() fields!: BvTableFieldArrayWithStickColumn
  @Prop() searchPlaceholder: string
  @Prop() tableName: string
  @Prop({ default: null }) localStorageName!: string | null
  @Prop() apiUrl!: string
  @Prop({ default: () => [] }) filterCols: { field: string, filter: string }[]

  // Define filter options. Those that we see in the dropdowns
  @Prop({ default: () => ({}) }) filters: IFilters
  /* // Example how to define filters:
  filters: IFilters = {
    itemFieldFilter: {
      filterName: 'field_name',
      selected: [],
      options: {
        option_1: this.$gettext('Option 1'),
        option_2: this.$gettext('Option 2')
      }
    }
  } */

  pageSize = 25
  tableItems: any[] = []
  searchString!: string
  currentPage = 1
  totalPages = 1
  itemCount = 0
  activeFilters: IFilter[] = []
  tableContext: BvTableCtxObject | null = null
  tableLoading = false
  hoveredRowId = 0

  handleSearchChange (value: string) {
    this.searchString = value
    this.$emit('search-update', this.searchString)
    this.currentPage = 1
    this.loadItems()
  }

  @Watch('pageSize')
  onPageSizeChanged () {
    this.loadItems()
  }

  @Watch('currentPage')
  onCurrentPageChanged () {
    if (!this.tableLoading) this.loadItems()
  }

  sortingChanged (ctx: BvTableCtxObject) {
    this.tableContext = ctx
    this.loadItems()
  }

  applyFilter (filter: IFilter) {
    this.activeFilters = this.activeFilters.filter(_filter => _filter.filterName !== filter.filterName)
    this.activeFilters.push(filter)
    if (this.localStorageName) { localStorage[this.localStorageName] = JSON.stringify(this.activeFilters) }
    this.$emit('filter-update', this.activeFilters)
    this.currentPage = 1
    this.loadItems()
  }

  getFilter (filterName: string): IFilter {
    return this.filters[filterName]
  }

  get computedFields () {
    // add a generic actions column that is basically not visible to add row actions
    const finalFields = this.fields.slice()
    finalFields.push({ key: 'actions', label: '' })
    finalFields.unshift({ key: 'index', label: this.$gettext('#') })
    return finalFields
  }

  get emptyText () {
    return this.$gettext('No entries found')
  }

  getSearchPlaceholder () {
    return this.searchPlaceholder ? this.searchPlaceholder : this.$gettext('Search…')
  }

  async loadItems () {
    this.tableLoading = true
    await axios.get(
      addContextToUrl(this.apiUrl, {
        sortBy: this.tableContext ? this.tableContext.sortBy : undefined,
        sortDesc: this.tableContext ? this.tableContext.sortDesc : undefined,
        search: this.searchString,
        filters: this.activeFilters,
        page: this.currentPage,
        pageSize: this.pageSize
      })
    ).then(response => {
      this.totalPages = response.data.total_pages
      this.currentPage = response.data.current_page
      this.tableItems = response.data.results
      this.itemCount = response.data.count
    }).catch(() => {
      this.$bvToast.toast(this.$gettext('Please try again or contact an administrator.'), {
        title: this.$gettext('Error'),
        toaster: 'b-toaster-top-left old',
        variant: 'variant',
        solid: true,
        toastClass: 'sf-toast'
      })
    })
    this.tableLoading = false
  }

  initLocalStorage (): void {
    if (this.localStorageName) {
      try {
        this.activeFilters = JSON.parse(localStorage[this.localStorageName])
        const activeFilterNames = this.activeFilters.map(filter => filter.filterName)
        const activeFiltersObj = this.activeFilters.reduce((result, item: IFilter) => {
          result[item.filterName] = item.selected
          return result
        }, {})
        for (const filterKey in this.filters) {
          const filter = this.filters[filterKey]
          if (activeFilterNames.includes(filter.filterName)) {
            filter.selected = activeFiltersObj[filter.filterName]
          }
        }
      } catch (e) {
        localStorage.removeItem(this.localStorageName)
      }
    }
  }

  async loadInitialItems () {
    this.$wait.start('fetch items')
    // initialize pre-selected filters
    for (const filterKey in this.filters) {
      const filter = this.filters[filterKey]
      if (filter.selected !== []) {
        this.activeFilters.push(filter)
      }
    }
    await Promise.all([
      this.loadItems()
    ])
    this.$wait.end('fetch items')
  }

  async created (): Promise<void> {
    moment.locale()
    this.loadInitialItems()
    this.initLocalStorage()
    this.handleSearchChange = debounce(this.handleSearchChange, 500)
  }
}
