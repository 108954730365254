import { IOrganization } from '@/types/projects'
import { IFilter } from '@/types/filters'
import { IAPIFilterCtx } from '@/types/base'

export function getLocale (salutation = true) {
  const localeEl = document.getElementById('user_locale')
  if (localeEl && localeEl.dataset) {
    const localeString = localeEl.dataset.locale || 'en'
    if (localeString.toLowerCase() === 'de') {
      if (salutation) return localeEl.dataset.salutation + '_' + 'DE'
      else return 'de'
    }
  }
  return 'en_US'
}

export function capitalize (value: string) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function addFiltersToUrl (filters?: IFilter[] | null, archived = undefined) {
  let filterString = ''
  if (filters) {
    filters.forEach(filter => {
      if (filter.selected.length) {
        filterString += `&${filter.filterName}=${filter.selected.toString()}`
      }
    })
  }
  if (archived !== undefined) {
    if (!archived) {
      filterString += '&exclude_status=archived'
    } else {
      filterString += '&archived=true'
    }
  }
  return filterString
}

export function addContextToUrl (url: string, ctx: IAPIFilterCtx) {
  let searchString = ''
  let sortString = ''
  const filterString = addFiltersToUrl(ctx.filters, ctx.archived)
  if (ctx.search) {
    searchString = `&search=${ctx.search}`
  }
  if (ctx.sortBy) {
    sortString = `&ordering=${ctx.sortDesc ? '-' : ''}${ctx.sortBy.split(/(?=[A-Z])/).join('_').toLowerCase()}`
  }
  if (url.endsWith('&')) {
    return `${url}page=${ctx.page}&page_size=${ctx.pageSize}${searchString}${filterString}${sortString}`
  } else {
    return `${url}?page=${ctx.page}&page_size=${ctx.pageSize}${searchString}${filterString}${sortString}`
  }
}

export const API_URLS = {
  V3: {
    ARCHIVE_PROJECTS: '/api/v3/actions/archive-projects/'
  },
  FUNDING_STATISTICS: {
    PROJECTS: '/api/v4/statistics/projects/',
    LEGACY: '/api/v4/statistics/legacy/',
    MONTHLY: '/api/v4/statistics/monthly/',
    CATEGORY: '/api/v4/statistics/categories/',
    FUNDING_TYPE: '/api/v4/statistics/funding-types/',
    IPP: '/api/v4/statistics/ipp/',
    EXPORT: '/api/v4/statistics/funding-export/'
  },
  ORGANIZATIONS: {
    LIST: '/api/v4/organizations/',
    MINIMAL_LIST: '/api/v4/organizations/minimal-list/',
    INTERNAL_STATUS: '/api/v4/organizations/internal-status/',
    RETRIEVE: (slug: string) => `/api/v4/organizations/${slug}/`,
    EXPORT: (filters: string) => `/api/v4/organizations/?${filters}&format=xlsx`,
    INFO: (slug: string) => `/api/v4/organization/${slug}/info/`
  },
  PROJECTS: {
    LIST: '/api/v4/projects/',
    MINIMAL_LIST: '/api/v4/projects/minimal-list/',
    ARCHIVE: '/api/v4/projects/archive/',
    RETRIEVE: (slug: string) => `/api/v4/projects/${slug}/`,
    EXPORT: (filters: string) => `/api/v4/projects/?${filters}&format=xlsx`,
    INFO: (slug: string) => `/api/v4/project/${slug}/info/`,
    DUPLICATE: '/api/v4/projects/duplicate/',
    FUND_INTERNALLY: (slug: string) => `/api/v4/projects/${slug}/fund-internally/`,
    DEFUND_INTERNALLY: (slug: string) => `/api/v4/projects/${slug}/defund-internally/`,
    CHECKED_MARKED_PAYMENTS: (slug: string) => `/api/v4/projects/${slug}/check-marked-payments/`,
    INTERNAL_STATUS: '/api/v4/projects/internal-status/'
  },
  USERS: {
    LIST: '/api/v4/users/?frontend=true&',
    EXPORT: (filters: string) => `/api/v4/users/?${filters}&frontend=true&format=xlsx`,
    PROFILE: '/api/v4/users/profile/',
    STAFF_LIST: '/api/v4/staff-users/',
    INVITE: '/api/v4/users/invite/'
  },
  PERMISSIONS: {
    LIST: '/api/v4/permissions/',
    EXPORT: '/api/v4/permissions/export/',
    USERS: (permissionName: string) => `/api/v4/permissions/users/${permissionName}/`
  },
  PRIVATE_DONATIONS: {
    LIST: '/api/v4/private-donations/',
    RETRIEVE: (id: string | number) => `/api/v4/private-donations/${id}/`,
    EXPORT: (filters: string) => `/api/v4/private-donations/?${filters}&format=xlsx`
  },
  REMINDERS: {
    LIST: '/api/v4/reminders/',
    PROJECT_LIST: (id: number | string) => `/api/v4/reminders/?target_type=project&target_id=${id}`,
    ORGANIZATION_LIST: (id: number | string) => `/api/v4/reminders/?target_type=organization&target_id=${id}`,
    RETRIEVE: (slug: string) => `/api/v4/reminders/${slug}/`,
    RESOLVE: (slug: string) => `/api/v4/reminders/resolve/${slug}/`,
    TEMPLATES: {
      LIST: '/api/v4/reminder-templates/',
      RETRIEVE: (slug: string) => `/api/v4/reminder-templates/${slug}/`
    }

  },
  ADDITIONAL_STEPS: {
    CHOICE_FIELDS: '/api/v4/additional-steps/fields/',
    CHOICE_FIELDS_AVAILABLE_IN_FUNDING_STATISTICS: '/api/v4/additional-steps/fields/by-type/?fields__available_in_funding_statistics=True',
    CHOICE_FIELDS_AVAILABLE_IN_DOCUMENTS: '/api/v4/additional-steps/fields/?available_in_documents=True',
    PROJECT_FIELDS: '/api/v4/additional-steps/project-fields/'
  },
  TABLE_SETTINGS: (tableName: string) => `/api/v4/table-settings/${tableName}/`,
  DOCUMENTS: {
    ADMINISTRATION: '/site-admin/documents/administration/',
    LIST: '/api/v4/documents/',
    MINIMAL_LIST: '/api/v4/documents/minimal-list/',
    CREATE: '/api/v4/documents/',
    RETRIEVE: (slug: string) => `/api/v4/documents/${slug}/`,
    PATCH: (slug: string) => `/api/v4/documents/${slug}/`,
    UPLOAD_PDF: (slug: string) => `/api/v4/documents/${slug}/upload-pdf/`,
    DELETE_PDFS: (slug: string) => `/api/v4/documents/${slug}/delete-pdfs/`,
    CREATE_PDF_LOG: `/api/v4/document-logs/`,
    CREATE_MULTI_PROJECTS_PDF_LOG: `/api/v4/document-logs/multi-projects/`,
    GENERATE_PDF: (slug: string) => `/api/v4/document-logs/${slug}/generate-pdf/`,
    AVAILABLE_FIELDS: (slug: string) => `/api/v4/documents/${slug}/get-available-fields/`
  },
  FILES: {
    RETRIEVE: (slug: string) => `/api/v3/ajax-upload/load-photos/?album_slug=${slug}`
  },
  PLATFORMFEATURES: {
    DEFAULT: '/api/v4/platform-features/1/'
  },
  LOGS: {
    SEARCHES: '/api/v4/logs/logged-searches',
    DEFUNDS: {
      LIST: '/api/v4/logs/defunds',
      EXPORT: (filters: string) => `/api/v4/logs/defunds/?${filters}&format=xlsx`
    }
  },
  EMAILS: {
    LOGS: {
      LIST: '/api/v4/emails/',
      REQUE: (id: number | string) => `/api/v4/emails/${id}/requeue/`,
      EXPORT: (filters: string) => `/api/v4/emails/?${filters}&format=xlsx`
    },
    TEMPLATES: {
      LIST: '/api/v4/email-templates/',
      RETRIEVE: (id: number | string) => `/api/v4/email-template/${id}/`,
      EXPORT: (filters: string) => `/api/v4/email-templates/?${filters}&format=xlsx`
    }
  },
  INDIVIDUAL_BANK_ACCOUNTS: {
    LIST: '/api/v4/individual-bank-accounts/'
  },
  COMPANIES: {
    DONATING_COMPANIES: '/api/v4/companies/donating-companies/'
  }
}

export const SITE_URLS = {
  PREVIEW: {
    ORGANIZATION: (organization: IOrganization) => `/organization/${organization.slug}/?preview_token=${organization.preview_token}`
  },
  SITE_ADMIN: {
    ORGANIZATIONS: {
      LIST: "/site-admin/organizations-old/",
      ARCHIVE_LIST: "/site-admin/organizations/archive-old/",
      CREATE: "/site-admin/organizations/create/",
      UPDATE: (slug: string) => `/site-admin/organization/${slug}/update/`
    }
  }
}
