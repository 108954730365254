






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ReminderStatus from '@/components/reminders/ReminderStatus.vue'
import { IReminder } from '@/types/reminders'

@Component({
  components: {
    ReminderStatus
  }
})
export default class ReminderDetails extends Vue {
  @Prop() reminder!: IReminder;

  get dueDate () {
    const date = new Date(this.reminder.scheduled_for)
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const month = date.getMonth() < 9 ? '0' + date.getMonth() + 1 : date.getMonth() + 1
    return `${day}.${month}.${date.getFullYear()}`
  }
}
