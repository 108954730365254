





import { Component, Mixins, Prop } from 'vue-property-decorator'
import { v4 as uuid4 } from 'uuid'
import { TGenericObject } from '@/types/base'
import LocaleMixin from '@/mixins/LocaleMixin'

@Component({
  name: 'localised-b-form-datepicker'
})
export default class LocalisedBFormDatepicker extends Mixins(LocaleMixin) {
  @Prop({ default: uuid4() }) id!: string

  labels = {
    de: {
      labelPrevDecade: 'Vorheriges Jahrzehnt',
      labelPrevYear: 'Vorheriges Jahr',
      labelPrevMonth: 'Vorheriger Monat',
      labelCurrentMonth: 'Aktueller Monat',
      labelNextMonth: 'Nächster Monat',
      labelNextYear: 'Nächstes Jahr',
      labelNextDecade: 'Nächstes Jahrzehnt',
      labelToday: 'Heute',
      labelSelected: 'Ausgewähltes Datum',
      labelNoDateSelected: 'Kein Datum gewählt',
      labelCalendar: 'Kalender',
      labelNav: 'Kalendernavigation',
      labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
    }
  }

  get attrsWithTranslations (): TGenericObject {
    return { ...this.$attrs, ...this.labels[this.locale] }
  }
}
