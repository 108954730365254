
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'locale-mixin'
})
export default class LocaleMixin extends Vue {
  get locale () {
    if (this.$language.current) {
      return (this.$language.current.startsWith('de_') || this.$language.current.endsWith('_DE')) ? 'de' : this.$language.current.replace('_', '-')
    }
    return 'en-US'
  }

  toCurrency (val: number | string, options: Intl.NumberFormatOptions = {}): string {
    if (typeof val === 'string') val = parseFloat(val)
    return val.toLocaleString(this.locale, { ...{ style: "currency", currency: "EUR" }, ...options })
  }

  toLocalisedDecimal (val: number | string, options: Intl.NumberFormatOptions = {}) {
    if (typeof val === 'string') val = parseFloat(val)
    return val.toLocaleString(this.locale, { ...{ style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }, ...options })
  }

  toLocalisedInteger (val: number | string) {
    if (typeof val === 'string') val = parseFloat(val)
    val = Math.round(val)
    return val.toLocaleString(this.locale)
  }

  formatBoolean (value: boolean): string {
    return value ? this.$gettext('Yes') : this.$gettext('No')
  }

  // Get localised date
  formatDate (dateStr: string): string {
    return this.$moment(dateStr).format('LLL')
  }
}
