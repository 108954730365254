import { Component, Vue } from 'vue-property-decorator'
import { BvTableFieldArrayWithStickColumn, ITableSettings } from '@/types/base'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'table-settings-mixin'
})
export default class TableSettingsMixin extends Vue {
  tableName = ''
  fields: BvTableFieldArrayWithStickColumn = []
  tableSettingsModalOpen = false
  tableSettings: ITableSettings | null = null

  get tableSettingsFields (): ({ key: string, label: string } | string)[] {
    const fieldsArray: ({ key: string, label: string } | string)[] = []
    for (let i = 0; i < this.fields.length; i++) {
      const field = this.fields[i]
      if (typeof field !== 'string') {
        if (field.label === '#') continue
        fieldsArray.push({ key: field.key, label: field.label })
      } else if (typeof field === 'string') {
        if (field === '#') continue
        fieldsArray.push(field)
      }
    }
    return fieldsArray
  }

  get fieldsFiltered (): BvTableFieldArrayWithStickColumn {
    return this.fields.filter(field => {
      if (!this.tableSettings) return true
      if (typeof field === 'string') return !this.tableSettings.hidden_fields.includes(field)
      return !this.tableSettings.hidden_fields.includes(field.key)
    })
  }

  async loadTableFields (): Promise<void> {
    await axios.get(API_URLS.TABLE_SETTINGS(this.tableName)).then(
      response => {
        this.tableSettings = response.data
      }
    )
  }

  tableSettingsSaveSuccess (res: any): void {
    this.tableSettings = res
    this.tableSettingsModalOpen = false
  }

  async created (): Promise<void> {
    this.loadTableFields()
  }
}
