







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SfSpinner extends Vue {
  @Prop({ default: 32 }) size!: number
}
