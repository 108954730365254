












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'page-size-select'
})
export default class PageSizeSelect extends Vue {
  @Prop({ default: 25 }) value!: number

  pageSizeOptions = {
    10: 10,
    25: 25,
    50: 50,
    100: 100
  }
}
