










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'sf-alert'
})
export default class SfAlert extends Vue {
  @Prop({ default: 'info' }) variant!: string
}
