












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'form-module'
})
export default class FormModule extends Vue {
  @Prop() title!: string
}
