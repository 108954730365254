














import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { BDropdown } from 'bootstrap-vue'
import SfCheckboxGroup from './SfCheckboxGroup.vue'

@Component({
  name: 'TableFilterDropdown',
  components: {
    SfCheckboxGroup
  }
})
export default class TableFilterDropdown extends Vue {
  @Prop() label!: string
  @Prop() filterName!: string
  @Prop({ default: () => [] }) options!: any
  @Prop({ default: () => [] }) selected!: string[]
  @Prop({ default: '' }) menuClass!: string
  $refs!: { dropdown: BDropdown }
  internalSelected: string[] = []

  @Watch('selected')
  onSelectedChanged (val: string[]): void {
    // Only update if those two diverge, or we will end up in an infinite loop
    if (!(
      val.length === this.internalSelected.length &&
      this.internalSelected.every((selectVal, idx) => selectVal === val[idx])
    )) {
      this.internalSelected = this.selected.slice()
    }
  }

  @Watch('internalSelected')
  onInternalSelectedChanged (val: string[]): void {
    this.$emit('update:selected', val)
  }

  applyFilters (): void {
    this.$refs.dropdown.hide(true)
    this.$emit('on-apply', { filterName: this.filterName, selected: this.selected })
  }

  get hasSelected (): boolean {
    return this.internalSelected.length > 0
  }

  get menuClasses (): string {
    return `dropdown-menu-search ${this.menuClass}`
  }

  mounted (): void {
    this.internalSelected = this.selected.slice()
  }
}
