





import { Component, Prop, Vue } from 'vue-property-decorator'
import { IProject } from '@/types/projects'

@Component({
  name: 'project-content-review-status'
})
export default class ProjectContentReviewStatus extends Vue {
  @Prop() project!: IProject

  get label (): string {
    let labelHtml = '-'
    const status = this.project.content_review_status
    if (status) {
      if (status.choice === 2) labelHtml = `<span class="label label-success">${status.display}</span>`
      else if (status.choice === 3) labelHtml = `<span class="label label-danger">${status.display}</span>`
    }
    return labelHtml
  }
}
