





import { Component, Prop, Vue } from 'vue-property-decorator'
import { IOrganization } from '@/types/projects'

@Component({
  name: 'organization-non-profit-review-status'
})
export default class OrganizationReviewStatus extends Vue {
  @Prop() organization!: IOrganization
  @Prop({ default: false }) showNOELabel!: boolean

  get label (): string {
    let labelHtml = '-'
    const status = this.organization.review_status
    if (status) {
      if (status.choice === 2) labelHtml = `<span class="label label-success">${status.display}</span>` // public
      else if (status.choice === 3) labelHtml = `<span class="label label-danger">${status.display}</span>` // rejected
      else if (status.choice === 4) labelHtml = `<span class="label label-success">${status.display}</span>` // accepted
    }
    if (this.showNOELabel && this.organization.is_notice_of_exemption_expired) {
      const noeLabel = `<span class="label label-danger">${this.$gettext('Proof expired')}</span>`
      if (labelHtml.length > 1) labelHtml += noeLabel
      else labelHtml = noeLabel
    }
    if (this.organization.needs_review) {
      const needsReviewLabel = `<span class="label label-warning">${this.$gettext('Needs review')}</span>`
      if (labelHtml.length > 1) labelHtml += needsReviewLabel
      else labelHtml = needsReviewLabel
    }
    return labelHtml
  }
}
