























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/bootstrap3/Modal.vue'

@Component({
  name: 'DeleteModal',
  components: {
    Modal
  }
})
export default class DeleteModal extends Vue {
  @Prop({ default: '' }) title!: string;
  @Prop({ default: false }) isOpen!: boolean;
}
