





import { IOrganization } from '@/types/projects'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'organization-content-review-status'
})
export default class OrganizationContentReviewStatus extends Vue {
  @Prop() organization!: IOrganization

  get label (): string {
    let labelHtml = '-'
    const status = this.organization.content_review_status
    if (status) {
      if (status.choice === 2) labelHtml = `<span class="label label-success">${status.display}</span>` // accepted
      else if (status.choice === 3) labelHtml = `<span class="label label-danger">${status.display}</span>` // rejected
    }
    return labelHtml
  }
}
