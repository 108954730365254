import { IFilter, IFilters } from '@/types/filters'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'persistent-filters-mixin'
})
export default class PersistentFiltersMixin extends Vue {
  @Prop({ default: null }) localStorageName!: string | null
  filters: IFilters = {}
  activeFilters: IFilter[] = []

  saveFiltersAndEmit (): void {
    localStorage[this.localStorageName] = JSON.stringify(this.activeFilters)
    this.$emit('filter-update', this.activeFilters)
  }

  applyFilter (filter: IFilter): void {
    this.activeFilters = this.activeFilters.filter(_filter => _filter.filterName !== filter.filterName)
    this.activeFilters.push(filter)
    this.activeFilters = this.activeFilters.filter(filter => filter.selected.length)
    this.saveFiltersAndEmit()
  }

  resetFilters (): void {
    for (const key in this.filters) {
      this.filters[key].selected = []
    }
    this.activeFilters = []
    this.saveFiltersAndEmit()
  }

  getFilter (filterName: string): IFilter {
    return this.filters[filterName]
  }

  created (): void {
    if (this.localStorageName) {
      try {
        this.activeFilters = JSON.parse(localStorage[this.localStorageName]).filter((filter: IFilter) => filter.selected.length)
        this.$emit('filter-update', this.activeFilters)
        const activeFilterNames = this.activeFilters.map(filter => filter.filterName)
        const activeFiltersObj = this.activeFilters.reduce((result, item: IFilter) => {
          result[item.filterName] = item.selected
          return result
        }, {})
        for (const filterKey in this.filters) {
          const filter = this.filters[filterKey]
          if (activeFilterNames.includes(filter.filterName)) {
            filter.selected = activeFiltersObj[filter.filterName]
          }
        }
      } catch (e) {
        localStorage.removeItem(this.localStorageName)
      }
    }
  }
}
