










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false
})
export default class InputGroup extends Vue {
  @Prop() icon!: string
  @Prop({ default: '' }) value!: string
  @Prop() placeholder?: string
  @Prop() onChange?: string

  handleChange (ev: MouseEvent) {
    const target = ev.target as HTMLInputElement
    this.$emit('update:value', target.value)
    this.$emit('on-change', target.value)
  }

  get iconClass (): string {
    return 'fa fa-' + this.icon
  }
}
