













import { Component, Prop, Vue } from 'vue-property-decorator'
import { v4 as uuid4 } from 'uuid'

@Component({
  name: 'SfCheckbox'
})
export default class SfCheckbox extends Vue {
  @Prop() title!: string
  @Prop() subTitle?: string
  @Prop() value!: string
  @Prop({ default: false }) checked!: boolean
  @Prop({ default: false }) hideCheckbox!: boolean
  @Prop() onCheckStore?: string
  @Prop() onUncheckStore?: string
  @Prop({ default: false }) useStore?: boolean

  checkboxChanged (ev: MouseEvent) {
    const target = ev.target as HTMLInputElement
    this.$emit('update:checked', target.checked)
    if (target.checked) {
      if (this.useStore && this.onCheckStore) this.$store.dispatch(this.onCheckStore, target.value)
      else this.$emit('check', target.value)
    } else if (!target.checked) {
      if (this.useStore && this.onUncheckStore) this.$store.dispatch(this.onUncheckStore, target.value)
      else this.$emit('uncheck', target.value)
    }
  }

  get uuid () {
    return uuid4()
  }
}
