































































































































import { Component, Prop, Watch, Ref, Mixins } from 'vue-property-decorator'
import { BvTableCtxObject, BvComponent } from 'bootstrap-vue'
import axios from 'axios'
import InputGroup from '@/components/bootstrap3/InputGroup.vue'
import SfCheckbox from '@/components/SfCheckbox.vue'
import { BvTableFieldArrayWithStickColumn, ITableSettings } from '@/types/base'
import { IFilter, IFilters } from '@/types/filters'
import TableFilterDropdown from '@/components/TableFilterDropdown.vue'
import OrganizationReviewStatus from '@/components/labels/OrganizationReviewStatus.vue'
import PersistentFiltersMixin from '@/mixins/PersistentFiltersMixin'
import { PlatformFeaturesModule, IPlatformFeatures } from '@/store/modules/platformFeatures'
import ProjectStatus from '@/components/labels/ProjectStatus.vue'
import ProjectReviewStatus from '@/components/labels/ProjectReviewStatus.vue'
import ProjectContentReviewStatus from '@/components/labels/ProjectContentReviewStatus.vue'
import ProjectVotingReviewStatus from '@/components/labels/ProjectVotingReviewStatus.vue'
import ProjectFinancingStatus from '@/components/labels/ProjectFinancingStatus.vue'
import ProjectFundingAccountLabel from '@/components/labels/ProjectFundingAccountLabel.vue'
import { ProjectsModule } from '@/store/modules/projects'
import { IIndividualBankAccount, IProject } from '@/types/projects'
import { API_URLS } from '@/utils/helpers'
import { IUser } from '@/types/users'
import { ICompany } from '@/types/companies'

@Component({
  components: {
    InputGroup,
    SfCheckbox,
    TableFilterDropdown,
    OrganizationReviewStatus,
    ProjectStatus,
    ProjectReviewStatus,
    ProjectContentReviewStatus,
    ProjectVotingReviewStatus,
    ProjectFinancingStatus,
    ProjectFundingAccountLabel
  },
  mixins: [PersistentFiltersMixin],
  name: 'project-table'
})
export default class ProjectTable extends Mixins(PersistentFiltersMixin) {
  // Table field definitions. Check https://bootstrap-vue.js.org/docs/components/table/ for more
  @Ref() readonly selectableTable!: BvComponent
  @Prop({ default: false }) archived!: boolean
  @Prop() fields!: BvTableFieldArrayWithStickColumn
  @Prop() searchString!: string
  @Prop() pageSize!: number
  @Prop() tableSettings!: ITableSettings | null
  @Prop() selectedProjects!: IProject[]
  projects: IProject[] = []
  selectProjectNames: string[] = []
  currentPage = 1
  tableContext: BvTableCtxObject | null = null
  tableLoading = false
  hoveredRowId = 0
  filterCols: { field: string, filter: string }[] = [
    { field: 'status', filter: 'projectStatusFilter' },
    { field: 'funding_type', filter: 'fundingTypeFilter' },
    { field: 'review_status', filter: 'reviewStatusFilter' },
    { field: 'financing_status', filter: 'financingStatusFilter' },
    { field: 'voting_review_status', filter: 'votingReviewStatusFilter' },
    { field: 'content_review_status', filter: 'contentReviewStatusFilter' },
    { field: 'internal_status', filter: 'internalStatusFilter' },
    { field: 'organization_status', filter: 'organizationStatusFilter' },
    { field: 'funding_account', filter: 'fundingAccountFilter' },
    { field: 'supported_by_companies', filter: 'supportedByCompaniesFilter' }
  ]

  filters: IFilters = {
    // Define filter options. Those that we see in the dropdowns
    projectStatusFilter: {
      filterName: 'status_named',
      selected: [],
      options: {
        in_progress: this.$gettext('In progress'),
        complete: this.$gettext('Complete')
      }
    },
    organizationStatusFilter: {
      filterName: 'organization_status_named',
      selected: [],
      options: {
        public: this.$gettext('Public'),
        rejected: this.$gettext('Rejected'),
        accepted: this.$gettext('Accepted'),
        needs_review: this.$gettext('Needs review'),
        noe_expired: this.$gettext('Proof expired')
      }
    },
    fundingTypeFilter: {
      filterName: 'funding_type_named',
      selected: [],
      options: {}
    },
    reviewStatusFilter: {
      filterName: 'review_status_named',
      selected: [],
      options: {
        needs_review: this.$gettext('Needs review'),
        public: this.$gettext('Public'),
        rejected: this.$gettext('Rejected'),
        accepted: this.$gettext('Accepted')
      }
    },
    financingStatusFilter: {
      filterName: 'financing_status',
      selected: [],
      options: {
        fully_funded: this.$gettext('Fully funded'),
        expired: this.$gettext('Expired'),
        can_receive_fundings: this.$gettext('In financing'),
        is_banned: this.$gettext('Banned')
      }
    },
    contentReviewStatusFilter: {
      filterName: 'content_review_status_named',
      selected: [],
      options: {
        accepted: this.$gettext('Content accepted'),
        rejected: this.$gettext('Content rejected')
      }
    },
    votingReviewStatusFilter: {
      filterName: 'voting_review_status_named',
      selected: [],
      options: {
        needs_review: this.$gettext('Needs review'),
        public: this.$gettext('Public'),
        rejected: this.$gettext('Rejected')
      }
    },
    fundingAccountFilter: {
      filterName: 'funding_account_named',
      selected: [],
      options: {}
    },
    internalStatusFilter: {
      filterName: 'internal_status__title',
      selected: []
    },
    supportedByCompaniesFilter: {
      filterName: 'supported_by_companies',
      selected: [],
      options: {}
    }
    /* supportedByUsersFilter: {
      filterName: 'supported_by_users',
      selected: [],
      options: {}
    } */
  }

  @Watch('pageSize')
  onPageSizeChanged () {
    this.loadProjects()
  }

  @Watch('currentPage')
  onCurrentPageChanged (): void {
    this.loadProjects()
  }

  @Watch('searchString')
  onSearchStringChanged (): void {
    this.loadProjects()
  }

  get platformFeatures (): IPlatformFeatures | null {
    return PlatformFeaturesModule.platformFeatures
  }

  getProjectCol (project: IProject): string {
    return `<a href="/site-admin/project/${project.slug}/update/">${project.title}</a>`
  }

  getInternalStatusCol (project: IProject): string {
    return project.internal_status ? project.internal_status.label : '-'
  }

  getOrganizationCol (project: IProject): string {
    return `<a href="/site-admin/organization/${project.organization.slug}/update/">${project.organization.title}</a>`
  }

  getFundingTypeCol (project: IProject): string {
    return `<span class="label label-info label-fundingtype-${project.funding_type.choice}">${project.funding_type ? project.funding_type.display : '-'}</span>`
  }

  sortingChanged (ctx: BvTableCtxObject): void {
    this.tableContext = ctx
    this.loadProjects()
  }

  rowHovered (row: IProject): void {
    if (row.id) {
      this.hoveredRowId = row.id
    }
  }

  applyFilterAndReload (filter: IFilter): void {
    this.applyFilter(filter)
    this.reloadProjects()
  }

  resetFiltersAndReload (): void {
    this.resetFilters()
    this.reloadProjects()
  }

  get emptyText () {
    return this.$gettext('No projects found')
  }

  get projectNameArray () {
    return this.projects.map(p => p.title)
  }

  get searchPlaceholder () {
    return this.$gettext('Search projects')
  }

  get projectCount () {
    return ProjectsModule.projectCount
  }

  get availableFields () {
    const _availableFields = this.fields.slice()
    if (this.tableSettings) {
      const fieldKeys = Object.keys(this.tableSettings.table_fields)
      const displayedFields = fieldKeys.filter(field => this.tableSettings && !this.tableSettings.hidden_fields.includes(field))
      displayedFields.unshift('selected', 'index')
      _availableFields.unshift({ key: 'selected', label: 'Select All', sortable: false }, { key: 'index', label: this.$gettext('#') })
      return _availableFields.filter(field => {
        const fieldName = typeof field === 'string' ? field : field.key
        return displayedFields.includes(fieldName)
      })
    }
    return []
  }

  get availableFilterCols () {
    if (this.tableSettings) {
      const fieldKeys = Object.keys(this.tableSettings.table_fields)
      return this.filterCols.filter(filterCol => {
        return this.tableSettings && !this.tableSettings.hidden_fields.includes(filterCol.field) && fieldKeys.includes(filterCol.field)
      })
    }
    return []
  }

  async loadProjects (): Promise<void> {
    this.tableLoading = true
    await ProjectsModule.fetchProjects({
      sortBy: this.tableContext ? this.tableContext.sortBy : undefined,
      sortDesc: this.tableContext ? this.tableContext.sortDesc : undefined,
      search: this.searchString,
      filters: this.activeFilters,
      page: this.currentPage,
      pageSize: this.pageSize,
      archived: this.archived
    })
    this.projects = ProjectsModule.projects
    this.tableLoading = false
  }

  async reloadProjects (): Promise<void> {
    this.tableLoading = true
    this.currentPage = 1
    this.loadProjects()
  }

  async loadInitialProjects (): Promise<void> {
    this.$wait.start('fetch projects')
    await Promise.all([
      this.loadProjects(),
      ProjectsModule.fetchInternalStatuses()
    ])
    // If we have internal statuses, create dict of them, add them to filter options and add column to table
    if (ProjectsModule.internalStatuses.length) {
      this.filters.internalStatusFilter.options = ProjectsModule.internalStatuses.reduce(
        (obj, item) => Object.assign(obj, { [item.title]: item.title }), {})
    }
    if (this.projects.length && Object.prototype.hasOwnProperty.call(this.projects[0], 'contentReviewStatus')) {
      this.filters.organizationStatusFilter.options = Object.assign(
        this.filters.organizationStatusFilter.options,
        { content_accepted: this.$gettext('Content accepted'),
          content_rejected: this.$gettext('Content rejected'),
          nonprofit: this.$gettext('nonprofit'),
          not_nonprofit: this.$gettext('Not nonprofit') })
    }
    this.$wait.end('fetch projects')
  }

  async loadBankAccounts () {
    const donation = 'donation'
    const sponsoring = 'sponsoring'
    const foundation = 'foundation'
    const gewinnsparen = 'gewinnsparen'
    if (this.platformFeatures && this.platformFeatures.enableDonations) {
      this.filters.fundingAccountFilter.options[donation] = this.$gettext('Donation Bank Account')
      this.filters.fundingTypeFilter.options[donation] = this.$gettext('Donation')
    }
    if (this.platformFeatures && this.platformFeatures.enableSponsoring) {
      this.filters.fundingAccountFilter.options[sponsoring] = this.$gettext('Sponsoring Bank Account')
      this.filters.fundingTypeFilter.options[sponsoring] = this.$gettext('Sponsoring')
    }
    if (this.platformFeatures && this.platformFeatures.enableFoundation) {
      this.filters.fundingAccountFilter.options[foundation] = this.$gettext('Foundation Bank Account')
      this.filters.fundingTypeFilter.options[foundation] = this.$gettext('Foundation')
    }
    if (this.platformFeatures && this.platformFeatures.enableGewinnsparen) {
      this.filters.fundingAccountFilter.options[gewinnsparen] = this.$gettext('Zweckertrag Bank Account')
      this.filters.fundingTypeFilter.options[gewinnsparen] = this.$gettext('Zweckertrag')
    }
    axios.get(API_URLS.INDIVIDUAL_BANK_ACCOUNTS.LIST).then(response => {
      const individualBankAccounts = response.data as IIndividualBankAccount[]
      for (const bankAccount of individualBankAccounts) {
        this.filters.fundingAccountFilter.options[bankAccount.slug] = bankAccount.title
      }
    })
  }

  async loadSupportedByOptions () {
    // axios.get(API_URLS.USERS.STAFF_LIST).then(response => {
    //   const staffUsers = response.data as IUser[]
    //   for (const usr of staffUsers) {
    //     this.filters.supportedByUsersFilter.options[usr.username] = usr.username
    //   }
    // })
    axios.get(API_URLS.COMPANIES.DONATING_COMPANIES).then(response => {
      const companies = response.data as ICompany[]
      for (const company of companies) {
        this.filters.supportedByCompaniesFilter.options[company.slug] = company.title
      }
    })
  }

  checkAll () {
    this.selectableTable.selectAllRows()
  }

  uncheckAll () {
    this.selectableTable.clearSelected()
  }

  onRowSelected (selectedItems: IProject[]) {
    this.$emit('update:selectedProjects', selectedItems)
  }

  selectProject (rowIndex: string) {
    this.selectableTable.selectRow(parseInt(rowIndex))
  }

  deselectProject (rowIndex: string) {
    this.selectableTable.unselectRow(parseInt(rowIndex))
  }

  async created (): Promise<void> {
    await PlatformFeaturesModule.fetchPlatformFeatures()
    this.loadInitialProjects()
    this.loadBankAccounts()
    this.loadSupportedByOptions()
  }
}
