











import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { v4 as uuid4 } from 'uuid'
import SfCheckbox from './SfCheckbox.vue'

@Component({
  name: 'SfCheckboxGroup',
  components: {
    SfCheckbox
  }
})
export default class SfCheckboxGroup extends Vue {
  @Prop({ default: () => [] }) options!: string
  @Prop({ default: () => [] }) selected!: string[]
  internalSelected: string[] = []

  @Watch('selected')
  onSelectedChanged (val: string[]): void {
    // Only update if those two diverge, or we will end up in an infinite loop
    if (!(
      val.length === this.internalSelected.length &&
      this.internalSelected.every((selectVal, idx) => selectVal === val[idx])
    )) {
      this.internalSelected = this.selected.slice()
    }
  }

  @Watch('internalSelected')
  onInternalSelectedChanged (val: string[], oldVal: string[]) {
    this.$emit('update:selected', val)
  }

  addItem (item: string) {
    this.internalSelected = [...this.internalSelected, item]
  }

  removeItem (item: string) {
    this.internalSelected = this.internalSelected.filter(x => x !== item)
  }

  get uuid () {
    return uuid4()
  }

  mounted (): void {
    this.internalSelected = this.selected.slice()
  }
}
