







































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import SfCheckboxGroup from '@/components/SfCheckboxGroup.vue'
import SfCheckbox from '@/components/SfCheckbox.vue'
import LocalisedBFormDatepicker from '@/components/LocalisedBFormDatepicker.vue'
import { TGenericObject } from '@/types/base'
import { IUser } from '@/types/users'
import { IReminder, IReminderTemplate } from '../../types/reminders'
import { API_URLS } from '@/utils/helpers'
import { IOrganization, IProject } from '@/types/projects'

@Component({
  components: {
    SfCheckboxGroup,
    SfCheckbox,
    LocalisedBFormDatepicker
  }
})
export default class ReminderForm extends Mixins(ToastMixin, UserMixin) {
  @Prop({ default: null }) project!: IProject | null;
  @Prop({ default: null }) organization!: IOrganization | null;
  @Prop({ default: false }) reminder!: IReminder | false;
  @Prop({ default: null }) reminderTemplate!: IReminderTemplate | null;

  dueDate = ''
  users: IUser[] = []
  selectedAssignees: number[] = []
  title = ''
  description = ''
  errors: TGenericObject = {}
  sendCompletedMail = true
  saving = false

  @Watch('reminder')
  onReminderChange () {
    if (this.reminder) {
      this.title = this.reminder.title
      this.description = this.reminder.description
      this.dueDate = this.formatDate(new Date(this.reminder.scheduled_for))
      this.selectedAssignees = this.reminder.users.map(user => user.id)
      this.sendCompletedMail = this.reminder.send_completed_mail
    } else {
      this.resetForm()
    }
  }

  @Watch('reminderTemplate')
  onReminderTemplateChange () {
    if (!this.reminderTemplate) {
      this.title = ''
      this.description = ''
    } else {
      this.title = this.reminderTemplate.title
      this.description = this.reminderTemplate.description
    }
  }

  formatDate (date: Date) {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    return [day, month, date.getFullYear()].reverse().join('-')
  }

  resetForm () {
    this.title = ''
    this.description = ''
    this.dueDate = ''
    this.selectedAssignees = []
    this.sendCompletedMail = false
  }

  reminderPostError (error: TGenericObject) {
    if (error.response && error.response.status) {
      this.makeToast(
        'danger',
        this.$gettext('Not authorized'),
        this.$gettext('You do not have the permission to perfom this action')
      )
    } else {
      this.makeToast(
        'danger',
        this.$gettext('Error'),
        this.$gettext('Failed to create/update task.')
      )
    }
  }

  async createReminder () {
    this.saving = true
    await axios.post(API_URLS.REMINDERS.LIST, this.postData)
      .then(() => this.$emit('refresh-reminders'))
      .catch(error => {
        this.reminderPostError(error)
      })
    this.resetForm()
    this.saving = false
  }

  async updateReminder () {
    if (!this.reminder) return
    this.saving = true
    await axios.patch(API_URLS.REMINDERS.RETRIEVE(this.reminder.slug), this.postData)
      .then(() => this.$emit('refresh-reminders'))
      .catch(error => {
        this.reminderPostError(error)
      })
    this.saving = false
  }

  get assigneButtonLabel () {
    if (this.selectedAssignees.length) {
      return this.selectedAssignees.length + ' ' + this.$gettext('selected')
    }
    return this.$gettext('Choose')
  }

  get assigneeOptions () {
    return this.users.reduce((obj: TGenericObject, item) => {
      obj[item.id] = item.full_name_w_username
      return obj
    }, {})
  }

  get postData () {
    return {
      title: this.title,
      description: this.description,
      scheduled_for: this.dueDate,
      targetId: this.project ? this.project.id : this.organization.id,
      targetType: this.project ? 'project' : 'organization',
      users: this.selectedAssignees,
      send_completed_mail: this.sendCompletedMail
    }
  }

  async fetchStaffUsers () {
    const staffUsers = await fetch(API_URLS.USERS.STAFF_LIST)
      .then(res => {
        if (!res.ok) { throw new Error(res.statusText) }
        return res.json()
      })
    this.users = staffUsers
  }

  async created () {
    this.fetchStaffUsers()
  }
}
